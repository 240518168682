import { COOKIE_MAX_AGE } from '../constants';

const getCookie = ({ id }) => {
  if (process.env.BROWSER && id) {
    const nameEQ = `${id}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
  }
  return null;
};

const removeCookie = ({ id, path = '/' }) => {
  if (process.env.BROWSER && id) {
    const maxAge = 'Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = `${id}=;path=${path};max-age=${maxAge};secure`;
  }
};

const setCookie = ({ id, value, path = '/' }) => {
  if (process.env.BROWSER && id && value) {
    document.cookie = `${id}=${value};path=${path};max-age=${COOKIE_MAX_AGE};secure`;
  }
};

export { getCookie, removeCookie, setCookie };
