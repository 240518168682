import queryString from 'query-string';
import {
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCT_PRICE_INTERVAL_START,
  GET_PRODUCT_PRICE_INTERVAL_SUCCESS,
  GET_PRODUCT_PRICE_INTERVAL_ERROR,
  GET_OCCASION_START,
  GET_OCCASION_SUCCESS,
  GET_OCCASION_ERROR,
  PER_PAGE,
  SORT_PRODUCTS_BY,
  UPDATE_FILTERS,
  UPDATE_SINGLE_FILTER,
  UPDATE_PRICE_INTERVAL,
  PRODUCT_SORTING,
  SALES_CHANNELS_GROUP,
} from '../constants';
import queryProductsFilter from './queries/filterProducts.graphql';
import queryProductPriceInterval from './queries/getProductPriceInterval.graphql';

function getFromState(state, name) {
  return (state && state.shop && state.shop[name]) || {};
}

export function getProducts({
  page = 1,
  perPage = PER_PAGE,
  params: {
    filters: {
      recipient = [],
      category = [],
      occasion = [],
      brand = [],
      type,
    } = {},
    orderBy = 'SortingPosition',
  } = {},
} = {}) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale, currency, countryCode },
      config: {
        salesChannelId,
        salesChannel: { group: salesChannelGroup = {} } = {},
      },
      basket: { vouchers },
    } = getState();
    const voucherBoughtByB2B =
      vouchers &&
      vouchers.find(voucher => voucher.boughtByB2bDepartment !== null);

    dispatch({
      type: GET_PRODUCTS_START,
      payload: {
        loading: true,
        perPage,
      },
      meta: {
        salesChannelId,
        countryCode,
        locale,
        currency,
      },
    });

    try {
      const variables = {
        input: {
          associatedB2bDepartment:
            voucherBoughtByB2B?.boughtByB2bDepartment || null,
          salesChannelId,
          expectedLanguage: locale,
          expectedCurrency: currency,
          includeRedeemableCountries: [countryCode],
          includePresentationKeys: [
            'productTitle',
            'productLogo',
            'productShortDescription',
            'productSlug',
          ],
          inCategories: [...recipient, ...category, ...occasion],
          inBrands: brand,
          productType: type && type.length === 1 ? type.toString() : null,
          orderBy: PRODUCT_SORTING[orderBy].submitValue,
          orderDirection: PRODUCT_SORTING[orderBy].direction,
        },
        withHasPricesInStock: salesChannelGroup === SALES_CHANNELS_GROUP.PORTAL,
        pagination: {
          page,
          perPage,
        },
      };
      const {
        data: {
          productsFilter: {
            items = [],
            pagingInfo: { totalItems },
          },
        },
        loading,
      } = await client.query({
        query: queryProductsFilter,
        variables,
        context: {
          service: 'cms',
          fetchPolicy: 'cache-first',
        },
      });

      const lastPage = Math.ceil(totalItems / PER_PAGE);
      const currentPage = (page * perPage) / PER_PAGE;
      const isLastPage = currentPage === lastPage || totalItems <= perPage;
      const nextPage = isLastPage ? currentPage : currentPage + 1;
      const infiniteLoad = !isLastPage;
      const isFirstLoad = page === 1;

      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: {
          language: locale,
          data: items.filter(
            // CMS sometimes returns duplicates
            (v, i, a) => a.findIndex(t => t.id === v.id) === i,
          ),
          isFirstLoad,
          currentPage,
          nextPage,
          loading,
          isLastPage,
          totalItems,
          infiniteLoad,
          manualLoad: !isLastPage && page === 1,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_ERROR,
        payload: {
          error,
          loading: false,
        },
      });
    }
    return getFromState(getState(), 'data');
  };
}

export function getProductPriceInterval({
  params: {
    filters: { recipient = [], category = [], occasion = [], brand = [] } = {},
  } = {},
} = {}) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale, currency, countryCode },
      config: { salesChannelId },
    } = getState();

    dispatch({
      type: GET_PRODUCT_PRICE_INTERVAL_START,
      payload: {
        loading: true,
      },
      meta: {
        salesChannelId,
        countryCode,
        locale,
        currency,
      },
    });

    try {
      const {
        data: {
          productPriceInterval: { max, min },
        },
      } = await client.query({
        query: queryProductPriceInterval,
        variables: {
          input: {
            salesChannelId,
            expectedCurrency: currency,
            includeRedeemableCountries: [countryCode],
            inCategories: [...recipient, ...category, ...occasion],
            inBrands: brand,
            productType: 'physicalProduct', // Price interval only works with physical products
          },
        },
        context: {
          service: 'cms',
          fetchPolicy: 'cache-first',
        },
      });

      dispatch({
        type: GET_PRODUCT_PRICE_INTERVAL_SUCCESS,
        payload: {
          min,
          max,
          currency,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCT_PRICE_INTERVAL_ERROR,
        payload: {
          currency,
          error,
        },
      });
    }
    return getFromState(getState(), 'productPriceInterval');
  };
}

export function updatePriceIntervals(min, max) {
  return async (dispatch, getState, { history }) => {
    dispatch({
      type: UPDATE_PRICE_INTERVAL,
      payload: {
        min,
        max,
      },
    });

    if (process.env.BROWSER) {
      const { pathname, search } = history.location;
      const parsedSearch = queryString.parse(search);
      parsedSearch.min = min;
      parsedSearch.max = max;

      history.replace({
        pathname,
        search: queryString.stringify(parsedSearch),
      });
    }
  };
}

export function updateFilters(id, checked, group) {
  return async (dispatch, getState, { history }) => {
    dispatch({
      type: UPDATE_SINGLE_FILTER,
      payload: {
        id,
        group,
        checked,
      },
    });

    if (process.env.BROWSER) {
      const { pathname, search } = history.location;
      const parsedSearch = queryString.parse(search);
      let newSearch = parsedSearch[group] ? parsedSearch[group].split(',') : [];

      if (checked) {
        if (group === 'type') {
          newSearch = [id];
        } else {
          newSearch.push(id);
        }
      } else {
        newSearch = newSearch.filter(key => key !== id);
      }

      newSearch.sort((a, b) => a - b);

      parsedSearch[group] =
        newSearch.length > 0 ? newSearch.toString() : undefined;

      history.replace({
        pathname,
        search: queryString.stringify(parsedSearch),
      });
    }
  };
}

export function clearFilters() {
  return async (dispatch, getState, { history }) => {
    history.replace(history.location.pathname);
  };
}

export function updateFiltersFromParams(params) {
  return {
    type: UPDATE_FILTERS,
    payload: {
      params,
    },
  };
}

export function sortProductsBy(sorting) {
  return async (dispatch, getState, { history }) => {
    dispatch({
      type: SORT_PRODUCTS_BY,
      payload: {
        sortBy: sorting,
      },
    });

    if (process.env.BROWSER) {
      const { pathname, search } = history.location;
      const parsedSearch = queryString.parse(search);
      parsedSearch.order = sorting;

      history.replace({
        pathname,
        search: queryString.stringify(parsedSearch),
      });
    }
  };
}

export function getOccasion(id, params = {}) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale, currency, countryCode },
      config: { salesChannelId },
    } = getState();

    const { orderBy = 'SortingPosition' } = params;

    dispatch({
      type: GET_OCCASION_START,
      payload: {
        loading: true,
      },
    });

    try {
      const {
        data: {
          productsFilter: { items = [] },
        },
        loading,
      } = await client.query({
        query: queryProductsFilter,
        variables: {
          input: {
            salesChannelId,
            expectedLanguage: locale,
            expectedCurrency: currency,
            includeRedeemableCountries: [countryCode],
            includePresentationKeys: [
              'productTitle',
              'productLogo',
              'productShortDescription',
            ],
            orderBy,
            inCategories: [id],
          },
          pagination: {
            page: 1,
            perPage: PER_PAGE,
          },
        },
        context: {
          service: 'cms',
        },
      });

      dispatch({
        type: GET_OCCASION_SUCCESS,
        payload: {
          occasion: id,
          data: items.filter(
            // CMS sometimes returns duplicates
            (v, i, a) => a.findIndex(t => t.id === v.id) === i,
          ),
          loading,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_OCCASION_ERROR,
        payload: {
          error,
          loading: false,
        },
      });
    }
    return getFromState(getState(), 'occasion');
  };
}
