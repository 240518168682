/* eslint-disable css-modules/no-unused-class */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import Icon from '../Icon';

import s from './Label.scss';

class Label extends PureComponent {
  static propTypes = {
    tag: PropTypes.string,
    label: PropTypes.node,
    children: PropTypes.node,
    icon: PropTypes.shape(),
    rtl: PropTypes.bool,
    uppercase: PropTypes.bool,
    subdued: PropTypes.bool,
    highlight: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    bold: PropTypes.bool,
    style: PropTypes.shape(),
  };

  static defaultProps = {
    label: undefined,
    children: undefined,
    tag: 'span',
    icon: undefined,
    uppercase: false,
    rtl: false,
    subdued: false,
    highlight: false,
    size: undefined,
    color: '',
    className: '',
    bold: false,
    style: {},
  };

  renderChildren = () => {
    const { icon, label, rtl, children } = this.props;
    const content = [];

    if (icon && !rtl) {
      content.push(<Icon className={s.icon} {...icon} />);
    }
    if (label) {
      content.push(label);
    }
    if (children) {
      content.push(children);
    }
    if (icon && rtl) {
      content.push(<Icon className={s.icon} {...icon} />);
    }

    return content;
  };

  render() {
    const {
      tag: Tag,
      icon,
      rtl,
      subdued,
      label,
      children,
      size,
      uppercase,
      color,
      highlight,
      className,
      bold,
      style,
      ...props
    } = this.props;
    const classes = cx(
      s.root,
      subdued && s.subdued,
      highlight && s.highlight,
      size && s[`${size}`],
      uppercase && s.uppercase,
      icon && s.hasIcon,
      rtl && s.rtl,
      bold && s.bold,
      className,
    );

    if (!icon) {
      return (
        <Tag className={classes} style={{ color, ...style }} {...props}>
          {label}
          {children}
        </Tag>
      );
    }

    return (
      <Tag className={classes} style={{ color, ...style }} {...props}>
        {icon && !rtl && (
          <Icon className={s.icon} fill="currentColor" {...icon} />
        )}
        {label && <span>{label}</span>}
        {children && <span>{children}</span>}
        {icon && rtl && (
          <Icon className={s.icon} fill="currentColor" {...icon} />
        )}
      </Tag>
    );
  }
}

export default withStyles(s)(Label);
