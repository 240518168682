export const DEFAULT_PAGE_TITLE = 'GoGift';
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_CURRENCY = 'dkk';
export const DEFAULT_COUNTRY = 'DK';
export const postCodeErrorComparisonString = 'post code';
export const postCodeErrorMessage = 'Invalid postal code';
export const productTitleKey = 'productTitle';
export const openInNewTabProp = '_blank';
export const valitorString = 'valitor';

// autoComplete attribute values for input fields
export const autoCompleteValues = {
  autoCompleteOn: 'on',
  autoCompleteOff: 'off',
};

export const voucherCodeWithPrefix = {
  POR: 'POR-',
  SGK: 'SGK-',
  BHN: 'BHN-',
};

export const LOCALES =
  // default locale is the first one
  [
    'en',
    // 'bg',
    // 'cs',
    'da',
    'de',
    // 'el',
    // 'es',
    'fi',
    // 'fr',
    // 'hu',
    // 'it',
    // 'ja',
    // 'nl',
    'no',
    // 'pl',
    // 'pt',
    // 'ro',
    // 'ru',
    // 'sk',
    // 'sl',
    'sv',
    // 'tr',
  ];
// Page Presentation keys mappings

export const PK_MAPPINGS = [
  {
    prefix: 'pageBannerImage_',
    attribute: 'image',
    name: 'banners',
  },
  {
    prefix: 'pageBannerLink_',
    attribute: 'link',
    name: 'banners',
  },
  {
    prefix: 'pageBannerTitle_',
    attribute: 'title',
    name: 'banners',
  },
  {
    prefix: 'pageBannerImageMobile_',
    attribute: 'mobileImage',
    name: 'banners',
  },
];

export const PK_MAPPINGS_SORT = {
  banners: {
    key: 'id',
    order: 'asc',
  },
};
// Disclaimer
export const DISCLAIMER_ACTION = {
  button: 'Button',
  checkbox: 'Checkbox',
  noAction: 'NoAction',
};

// Routes

export const BASKET_PATH = '/basket';
export const REDEEM_PATH = '/redeem';
export const SHOP_PATH = '/shop';
export const OCCASIONS_PATH = '/occasion';
export const ACCOUNT_PATH = '/business/my/account';
export const ACCOUNT_CREATE_PATH = '/business/my/account-create';
export const ORDERS_PATH = '/business/my/orders';
export const MY_PATH = '/business/my';
export const SELECT_DEPARTMENT_PATH = '/business/my/department';
export const WELCOME_PATH = '/welcome';
export const GLOBAL_SITE_PATH = 'http://global.gogift.com/';
export const LOGOUT_PATH = '/logout';
export const VERIFY_OWNER_PATH = '/verify-owner';
export const GIFTCARD_PATH = '/giftcard';
export const GIFTCARD_REDEEM_PATH = '/giftcard-redeem';
export const DISPLAY_CATALOG = '/display-catalog';
export const NOT_FOUND_PATH = '/not-found';
export const SUPPORT_PATH = 'https://www.support.gogift.com/';
export const whiteLabelPath = (salesChannel, path) =>
  salesChannel ? `/${salesChannel}${path}` : path;

// Hardcoded pages

export const ABOUT_US_PATH = '/about-us';
export const CONTACT_PATH = '/contact';
export const BLOCKED_DEPARTMENT_PATH = '/blocked-department';
export const COOKIE_POLICY_PATH = '/cookie-policy';
export const FAQ_PATH = '/faq';
export const TERMS_AND_CONDITIONS_PATH = '/terms-and-conditions';
export const PRIVACY_POLICY_PATH = '/privacy-policy';
export const B2B_PATH = '/b2b';

export const QUERY_PARAMS = {
  SHOW_GLOBAL_LINK: 'showGlobalLink',
};
export const SITEMAP_PATHS = [
  '',
  REDEEM_PATH,
  SHOP_PATH,
  CONTACT_PATH,
  ABOUT_US_PATH,
  FAQ_PATH,
  TERMS_AND_CONDITIONS_PATH,
  PRIVACY_POLICY_PATH,
];

// Products
export const PRODUCT_SORTING = {
  SortingPosition: {
    value: 'SortingPosition',
    submitValue: 'SortingPosition',
    label: 'sortingPosition',
    direction: 'Asc',
  },
  Alphabetic: {
    value: 'Alphabetic',
    submitValue: 'Alphabetic',
    label: 'alphabetic',
    direction: 'Asc',
  },
  PriceLowToHigh: {
    value: 'PriceLowToHigh',
    submitValue: 'Price',
    label: 'sortingLowToHighPrice',
    direction: 'Asc',
  },
  PriceHighToLow: {
    value: 'PriceHighToLow',
    submitValue: 'Price',
    label: 'sortingHighToLowPrice',
    direction: 'Desc',
  },
};

export const MKT_TEAM_SPECIAL_REDIRECTS = {
  '/fi/fi/eur/shop/superlahjakortti/256459888984358912':
    '/fi/fi/eur/shop/superlahjakortti/698167931167162368',
  '/en/dk/dkk/shop/ilva-dk-gift-card/269683453896114176':
    '/da/dk/dkk/shop/ilva-dk-gavekort/729656296614506496',
  '/da/dk/dkk/shop/just-eat-gavekort/269600385478901760': '/da/dk/dkk',
  '/fi/fi/eur/shop/motonet-lahjakortti/259575784091123712':
    '/fi/fi/eur/shop/motonet-fi-lahjakortti/687635650545823744',
  '/sv/se/nok/shop/superpresentkortet/256458273929854976':
    '/sv/se/sek/shop/superpresentkortet/698127934611374080',
  '/sv/se/sek/shop/mathem-presentkort/268529202834522112':
    'https://engine.gogift.io/310730158879584256/mathem',
  '/sv/se/sek/shop/the-body-shop-presentkort/437949297330307072':
    'https://engine.gogift.io/308539394133520384/thebodyshopse',
};

// Production
export const GLOBAL_GIFTCARD_PATH =
  '/shop/the-global-gift-card/256472009205907456';

// Check if a url is external ie IS_EXTERNAL.test(url)
export const IS_EXTERNAL = /^https?:\/\//;
// Days ahead sms and email are allowed to be delivered in ms. Default to roughly 3 months (90 days)
export const MAX_DATE_DELIVERY_DELTA = 90 * 24 * 60 * 60000;
export const CACHE_HEADER_MAX_AGE = 60 * 15; // 15 minutes
export const CACHE_HEADER_S_MAX_AGE = 3600 * 2; // 2 hours
export const COOKIE_MAX_AGE = 90 * 24 * 3600; // 90 days, number of days cookies are kept
export const SELECT_TO_AUTOCOMPLETE_BREAKPOINT = 10; // Number of items in price dropdown before it turns into an autocomplete

// Anonymous, Blocked and Redeem used for mode only
export const ACCOUNT_TYPE = {
  ANONYMOUS: 'Anonymous',
  B2B: 'B2BDepartment',
  B2C: 'B2C',
  REDEEM: 'Redeem',
  BLOCKED: 'Blocked',
};

export const SALES_CHANNELS = {
  B2B: '1',
  B2C: '3',
  REDEEM: '2',
};

export const SALES_CHANNELS_GROUP = {
  PORTAL: 'Portal',
  WEBSITE: 'Website',
};

// My Pages
export const ORDER_DOWNLOAD_PREFIX = 'gogift-order-';
export const DEPARTMENT_STATUS = {
  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
  PENDING: 'ActivePendingReview',
};
export const DEPARTMENT_USER_STATUS = {
  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
};

// Gift cards

export const EXPORTTYPE_TO_CONTENTTYPE = {
  csv: 'text/csv',
  pdf: 'application/pdf', // default
  bundle: 'application/pdf',
};

export const EXPORTTYPE_TO_FILE_EXTENSION = {
  csv: 'csv',
  pdf: 'pdf', // default
};

export const GIFTCARD_EXPORT_BEHAVIOUR = {
  file: 'File',
  fileAndRedeem: 'FileAndRedeem',
};

export const GIFTCARD_EXPORT_TYPE = {
  pdf: 'pdf',
  bundle: 'bundle',
};

// Greetings
export const MAX_FILE_UPLOAD_SIZE = 5000000; // 5MB
export const ACCEPT_IMAGE_CONTENT_TYPES = [
  'image/png',
  'image/jpeg',
  'image/pjpeg',
];

// Shop Settings

export const PRODUCT_TYPE = {
  giftCard: 'Giftcard',
  physicalProduct: 'PhysicalProduct',
};

export const PER_PAGE = 48;

export const PRODUCT_TYPE_FILTERS = [
  {
    id: 'giftCard',
    label: 'Giftcards',
  },
  {
    id: 'physicalProduct',
    label: 'Products',
  },
];

export const LOCALEDICT = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  DA: 'Danish',
  DE: 'German',
  EN: 'English',
  FI: 'Finnish',
  NO: 'Norwegian',
  SV: 'Swedish',
  /* @intl-code-template-end */
};

export const COUNTRY_NAMES = {
  AU: 'Australia',
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CA: 'Canada',
  CZ: 'Czechia',
  CN: 'China',
  DK: 'Denmark',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IN: 'India',
  IE: 'Ireland',
  IT: 'Italy',
  JP: 'Japan',
  LU: 'Luxembourg',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  RU: 'Russia',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ZA: 'South Africa',
  ES: 'Spain',
  CH: 'Switzerland',
  SE: 'Sweden',
  TR: 'Turkey',
  GB: 'United Kingdom',
  US: 'United States',
  ID: 'Indonesia',
  IL: 'Israel',
  KR: 'Korea',
  MY: 'Malaysia',
  NZ: 'New Zealand',
  PH: 'Phillipines',
  SG: 'Singapore',
  TH: 'Thailand',
};

export const SHIPPING_METHODS = {
  DAO: 'DAO',
  PostNordParcel: 'PostNord Parcel',
  Varubrev: 'Varubrev',
  REK: 'REK',
  InternationalParcel: 'International Parcel',
  Pacsoft: 'Pacsoft',
  MyPackCollect: 'MyPack Collect',
  CoolEurope: 'CoolEurope',
  MyPackHome: 'MyPack Home',
};

export const DELIVERY_CURRENCIES = {
  sek: 'SEK',
  nok: 'NOK',
  eur: 'EUR',
};

export const THRESHOLD_CARD_VALUE = {
  seThreshold: 2000,
  fiThreshold: 200,
};

export const DEFAULT_COUNTRY_NAMES = {
  DK: COUNTRY_NAMES.DK,
  FI: COUNTRY_NAMES.FI,
  NO: COUNTRY_NAMES.NO,
  SE: COUNTRY_NAMES.SE,
  DE: COUNTRY_NAMES.DE,
};

export const FOOTERBAR_LINKS = {
  pages: [
    {
      to: TERMS_AND_CONDITIONS_PATH,
      label: 'termsAndConditions',
    },
  ],
};

export const FOOTER_LINKS = {
  policies: [
    {
      label: 'privacyPolicy',
      to: PRIVACY_POLICY_PATH,
    },
    {
      label: 'cookiePolicy',
      to: COOKIE_POLICY_PATH,
    },
  ],

  pages: [
    {
      to: TERMS_AND_CONDITIONS_PATH,
      label: 'termsAndConditions',
    },
  ],
};

export const usps = [
  {
    key: 'usp1',
    label: 'usp1',
    icon: 'STARS',
  },
  {
    key: 'usp2',
    label: 'usp2',
    icon: 'EMAIL',
  },
  {
    key: 'usp3',
    label: 'usp3',
    icon: 'LAPTOP_FEATHER',
  },
];

export const superGiftCardMenu = [
  {
    id: 'buy-supergiftcard',
    label: 'buySuperGiftCard',
    to: GLOBAL_GIFTCARD_PATH,
    hideForModes: [ACCOUNT_TYPE.REDEEM, ACCOUNT_TYPE.ANONYMOUS],
    isSuperCardBuy: true,
  },

  {
    id: 'redeem-supergiftcard',
    label: 'redeemSuperGiftCard',
    to: REDEEM_PATH,
    hideForModes: [ACCOUNT_TYPE.B2B],
  },
];

export const localSuperGiftCardMenu = [
  {
    id: 'buy-localgiftcard',
    label: 'buyLocalSuperGiftCard',
    hideForModes: [ACCOUNT_TYPE.REDEEM],
    isSuperCardBuy: true,
  },
  {
    id: 'redeem-localgiftcard',
    label: 'redeemLocalSuperGiftCard',
    to: REDEEM_PATH,
    hideForModes: [ACCOUNT_TYPE.B2B],
  },
];

export const menuLinksTop = [
  {
    label: 'seeAllGiftcards',
    to: SHOP_PATH,
  },
];

export const MAX_LINKS_IN_DROPDOWN = 40;

export const menuLinksBottom = [
  {
    label: 'help',
    to: FAQ_PATH,
  },
  {
    label: 'contact',
    to: CONTACT_PATH,
  },
  {
    label: 'cookiePolicy',
    to: COOKIE_POLICY_PATH,
  },
  {
    label: 'privacyPolicy',
    to: PRIVACY_POLICY_PATH,
  },
  {
    label: 'termsAndConditions',
    to: TERMS_AND_CONDITIONS_PATH,
  },
];

export const AUTHORIZATION_SCHEMES = {
  byEmail: 'ByEmail',
  bySms: 'BySms',
  byAccountId: 'ByAccountId',
  byPin: 'ByPin',
  noAuthorization: 'NoAuthRequired',
};

export const PAYMENT_METHODS = {
  INVOICE: 'invoiceByFinance',
  PSP: 'externalpsp',
  TRUSTLY: 'trustly',
  ADYEN: 'adyen',
};

export const DELIVERY_METHODS = {
  PHYSICAL: 'Physical',
  SMS: 'Sms',
  EMAIL: 'Email',
  CSV: 'CsvByEmail',
  WEBHOOK: 'Webhook',
};

export const DELIVERY_ORDER = [
  DELIVERY_METHODS.EMAIL,
  DELIVERY_METHODS.SMS,
  DELIVERY_METHODS.PHYSICAL,
  DELIVERY_METHODS.CSV,
];

// Used in product page when adding a physical product to basket, as backend dont accept emty address lines and delivery address will be set wehn choosing pickup point.
export const MOCK_TEXT = 'MockText';

export const RETAIL_PRICE = 'RetailPrice';

export const URL_EXCEPTION = ['/redeem/', 'type=physicalProduct'];
