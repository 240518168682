import { urlPrefix } from '../helpers';

export const getCountryCodeAndLangFromParsedLanguages = ({ languages }) =>
  languages.reduce((acc, val) => {
    acc.lang = acc.lang || val.code;
    acc.countryCode = acc.countryCode || val.region;
    return acc;
  }, {});

// Left as separate functions on purpose as logic may change
export const getValidCountryCode = ({ countryCodes, countries }) =>
  countryCodes.find(countryCode =>
    countries?.find?.(
      ({ value }) => countryCode && countryCode.toUpperCase() === value,
    ),
  );

export const getValidLang = ({ languageCodes, languages }) =>
  languageCodes.find(languageCode =>
    languages.find(
      ({ value }) => languageCode && languageCode.toLowerCase() === value,
    ),
  );

export const getValidCurrency = ({ currencyCodes, currencies }) =>
  currencyCodes.find(currencyCode =>
    currencies.find(
      ({ value }) =>
        currencyCode && currencyCode.toUpperCase() === value.toUpperCase(),
    ),
  );

export const getPrefixedPathname = ({
  pathname,
  countryCode,
  locale,
  currency,
}) => {
  if (pathname) {
    const paths = pathname.split('/');
    // First is '', second language code, third country code
    if (paths.length > 3 && paths[0].length === 0) {
      return urlPrefix(`/${paths.slice(4).join('/')}`, {
        locale,
        countryCode,
        currency,
      });
    }
  }
  return pathname;
};

export const getIntlFromPathname = ({ pathname }) => {
  let countryCode;
  let currency;
  let locale;
  if (pathname) {
    const paths = pathname.split('/');
    if (paths.length > 1 && paths[1].length === 2) {
      locale = paths[1];
    }
    if (paths.length > 2 && paths[2].length === 2) {
      countryCode = paths[2].toUpperCase();
    }
    if (paths.length > 3 && paths[3].length === 3) {
      currency = paths[3].toUpperCase();
    }
  }

  return {
    countryCode,
    currency,
    locale,
  };
};

export const translateKey = ({ key, page, locale }) =>
  page[key] || `Missing presentation key "${key}" (${locale})`;
