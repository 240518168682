/* eslint-disable*/
const formatJsLoggerHelper = (err, language) => {
  const errCode = err?.code;
  if (
    errCode === 'MISSING_TRANSLATION' ||
    errCode == 'FORMAT_ERROR' ||
    err?.message?.startsWith('[@formatjs/intl Error')
  ) {
    console.log('Missing translation', err.descriptor?.id);

    if (typeof sessionStorage !== 'undefined') {
      const missingKeysByLanguage =
        JSON.parse(sessionStorage.getItem('missingKeysByLanguage')) || {};
      const missingKeys = missingKeysByLanguage[language] || [];

      const key = err.descriptor?.id;
      if (key && !missingKeys.includes(key)) {
        missingKeys.push(key);
        missingKeysByLanguage[language] = missingKeys;
        sessionStorage.setItem(
          'missingKeysByLanguage',
          JSON.stringify(missingKeysByLanguage),
        );
      }
    }
    return false;
  }
  throw err;
};
export { formatJsLoggerHelper };
